
.span {    
  background: radial-gradient(circle,red, purple);
  color: transparent;
  font-family: Inter;
  font-weight: 400;
  font-size: 10.5px;
  line-height: 15.75px;
  letter-spacing: 0px;
  width: 254px;
  height: 16px;
  -webkit-background-clip: text;
  background-clip: text;
}

.title {
  font-family: Roboto;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.description {
  font-family: var(--font-family-light);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: justify;
  justify-content: normal;
  justify-items: normal;
  font-size: var(--font-size-h6);
  color: var(--color-grey-600);
  width: 100%;
}

.table {
  border-collapse: collapse;
  font-family: var(--font-family-light);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  color: var(--color-grey-600);
  text-align: left;
  width: 100%;
  font-size: var(--font-size-h6);
}

.card {
  border: '1px solid #8A8D9C';
  border-radius: 10px;
}
  
.cardSelected {
  border: 3px solid #007C92 !important;
  border-radius: 20px;
}

.tableTitles {
  font-family: var(--font-family-bold);
  font-weight: 600;
}

.imageStyle {
  width: 340px;
  height: 137.42px;
  padding: 2px;
}

.desc {
  width: 370px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
  display: flex;
}

.details {
  padding: 30px;
}

.tr {
  padding: 10px !important;
  border-bottom: 1pt solid grey;
}