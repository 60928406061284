.formwrapper {
  min-height: 200px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.titleHeading {
  color: var(--color-grey-700);
  font-family: var(--font-family-medium);
  font-style: normal;
  font-size: var(--font-size-h5);
  line-height: var(--font-line-height-default);
  margin: 0 0 5px 0;
}

.titleSubHeading {
  color: var(--color-grey-700);
  font-style: normal;
  font-size: var(--font-size-b1);
  line-height: var(--font-line-height-default);
  white-space: pre-line;
}

.DateContainer {
  display: flex;
  margin-bottom: 10px;
}

.DateContainer > div > span {
  padding-left: 1.3rem;
}

.erroredDateContainer {
  display: flex;
  margin-bottom: 10px;
}

.erroredDateContainer > div > span {
  padding-left: 1rem;
}
