.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 23px;
  width: 85% !important;
  background-color:'red'
}

.titleHeading {
  color: var(--color-grey-700);
  font-family: var(--font-family-medium);
  font-style: normal;
  font-size: var(--font-size-h5);
  line-height: var(--font-line-height-default);
  margin: 0 0 20px 0;
}

.titleSubHeading {
  color: var(--color-grey-700);
  font-style: normal;
  font-size: var(--font-size-b1);
  line-height: var(--font-line-height-default);
  white-space: pre-line;
}
@media (min-width: 768px) {
  .titleHeading {
    font-size: var(--font-size-h5);
  } 
}