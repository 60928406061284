.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
  }
  
  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0 10px 0;
    width: 100%;
  }
  
  .mobileTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 16px;
    width: 100%;
  }
  
  .titleHeading {
    color: var(--color-grey-700);
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-size: var(--font-size-h3);
    font-family: var(--font-family-medium);
    line-height: var(--font-line-height-default);
    margin: 0 0 20px 0;
    width: inherit;
  }
  
  .mobileTitleHeading {
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-size: var(--font-size-h5);
    font-family: var(--font-family-medium);
    line-height: var(--font-line-height-default);
    margin: 0 0 16px 0;
    width: inherit;
    padding: 0 16px;
  }
  
  .titleSubHeading {
    color: var(--color-grey-700);
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-size: var(--font-size-b1);
    line-height: var(--font-line-height-default);
    width: inherit;
    white-space: pre-line;
  }
  
  .formFieldRadioContainer {
    display: flex;
    justify-content: left;
    margin: 12px 0 10px 0;
    width: 32.6rem;
    max-width: 100%;
  }
  
  .formWrapper {
    width: 100%;
    max-width: 32.6rem;
    padding: 0 16px;
  }
  
  .subTextMargin {
    margin-bottom: 20px;
  }
  .errorContaine > div > input {
    border: 1px solid red !important;
  }
   
  .errorContaine > div {
    margin-bottom: 30px;
  }

  .fieldRow {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1rem;
  }

  .halfWidth {
    flex: 1;
    min-width: 0;
  }

  .halfWidth > div {
    width: 100% !important;
  }

  .halfWidth input,
  .halfWidth select {
    width: 100% !important;
  }
  
  .categoryContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
  }
  
  .categoryWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 32.6rem;
    max-width: 100%;
  }
  
  .categoryHeading {
    color: var(--neutral-black-08081-b, #08081B);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 8px;
    padding: 0 16px;
  }
  
  .categoryField {
    flex: 1;
  }
  
  /* Mobile Specific Styles */
  @media screen and (max-width: 768px) {
    .container {
      margin-bottom: 80px;
    }

    .formWrapper {
      padding: 0 16px;
    }

    .fieldRow {
      flex-direction: column;
      gap: 0;
    }

    .halfWidth {
      width: 100%;
      margin-bottom: 10px;
    }

    .formFieldRadioContainer {
      width: 100%;
      padding: 0 16px;
    }

    .categoryHeading {
      font-size: 14px;
      margin-bottom: 6px;
    }

    /* Add spacing between stacked fields */
    .halfWidth:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  /* Ensure form fields are full width on mobile */
  @media screen and (max-width: 520px) {
    .formWrapper,
    .formFieldRadioContainer,
    .fieldRow,
    .halfWidth,
    .halfWidth > div {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  