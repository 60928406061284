.Container > div > section {
  border-radius: 10px;
  background-color: var(--color-white);
  border-color: var(--color-grey-200);
  width: 600px;
  height: 60px;
}

.Container > div > section > hr {
  display: none;
}