.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* margin-bottom: 100px; */
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0 10px 0;
  width: 100%;
}

.label {
  margin-bottom: 10px;
  font-family: var(--font-family-medium);
  width: inherit;
}

.mobileTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 16px;
  width: 100%;
}

.titleHeading {
  color: var(--color-grey-700);
  display: flex;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-size: var(--font-size-h3);
  font-family: var(--font-family-medium);
  line-height: var(--font-line-height-default);
  margin: 0 0 20px 0;
  width: inherit;
}

.mobileTitleHeading {
  display: flex;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-size: var(--font-size-h5);
  font-family: var(--font-family-medium);
  line-height: var(--font-line-height-default);
  margin: 0 0 16px 0;
  width: inherit;
  padding: 0 16px;
}

.titleSubHeading {
  color: var(--color-grey-700);
  display: flex;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-size: var(--font-size-b1);
  line-height: var(--font-line-height-default);
  width: inherit;
  white-space: pre-line;
}

.formFieldRadioContainer {
  display: flex;
  justify-content: left;
  margin: 12px 0 10px 0;
  width: 37.5rem;
  max-width: 100%;
}

.formFieldDateSelector {
  display: flex;
  justify-content: left;
  margin: 0px 0 10px 0;
}

.formFieldDateSelector > div > span {
  text-align: left;
  padding: 0px 0px 0px 20px;
}

.formWrapper {
  width: 100%;
  max-width: 39.8rem;
  padding: 0 16px;
}

.subTextMargin {
  margin-bottom: 20px;
}

.errorContainer > input {
  border: 1px solid red !important;
  width: 37.5rem !important;
  max-width: 37.5rem !important;
}
   
.errorContainer {
  margin-bottom: 30px !important;
}

.errorContainer > button {
  border: 1px solid red !important;
  width: 37.5rem !important;
  max-width: 37.5rem !important;
}

.fieldRow {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.halfWidth {
  flex: 1;
  min-width: 0;
}

/* .halfWidth > div {
  width: 100% !important;
} */

.halfWidth input,
.halfWidth button {
  width: 100% !important;
}

.categoryContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.errorDateFieldContainer {
  border: 1px solid red !important;
  border-radius: 10px;
  margin-bottom: 30px;
}

.categoryWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 32.6rem;
  max-width: 100%;
}

.categoryHeading {
  color: var(--neutral-black-08081-b, #08081B);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 8px;
  padding: 0 16px;
}

.fieldContainer > input {
  width: 37.5rem !important;
  max-width: 37.5rem !important;
}

.fieldContainer > button {
  width: 37.5rem !important;
  max-width: 37.5rem !important;
}


.categoryField {
  flex: 1;
}

/* Mobile Specific Styles */
@media screen and (max-width: 768px) {
  /* .container {
    margin-bottom: 80px;
  } */

  .formWrapper {
    padding: 0 16px;
  }

  .fieldRow {
    flex-direction: column;
    gap: 0;
  }

  .halfWidth {
    width: 100%;
    margin-bottom: 10px;
  }

  .formFieldRadioContainer {
    width: 100%;
    padding: 0 16px;
  }

  .formFieldDateSelector {
    width: 86% !important;
    max-width: 86% !important;
  }

  /* .fieldContainer {
    width: 85% !important;
    max-width: 85% !important;
  } */

  .fieldContainer > input {
    width: 100% !important;
    max-width: 100% !important;
  }
  
  .fieldContainer > button {
    width: 100% !important;
    max-width: 100% !important;
  }

  .errorContainer > input {
    border: 1px solid red !important;
    width: 100% !important;
    max-width: 100% !important;
  }
     
  .errorContainer {
    margin-bottom: 30px !important;
  }
  
  .errorContainer > button {
    border: 1px solid red !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  /* .halfWidth
  .halfWidth > div {
    width: 100% !important;
    max-width: 100% !important;
  } */

  .categoryHeading {
    font-size: 14px;
    margin-bottom: 6px;
  }

  /* Add spacing between stacked fields */
  .halfWidth:not(:last-child) {
    margin-bottom: 16px;
  }
}

/* Ensure form fields are full width on mobile */
@media screen and (max-width: 628px) {
  .formWrapper,
  .formFieldRadioContainer {
    width: 100% !important;
    max-width: 100% !important;
  }

  .formFieldDateSelector > div > span {
    padding-left: 15px;
  }
  /* .fieldContainer {
    width: 75% !important;
    max-width: 75% !important;
  } */

  /* .fieldContainer > input {
    width: 85% !important;
    max-width: 85% !important;
  }
  
  .fieldContainer > button {
    width: 85% !important;
    max-width: 85% !important;
  } */

  .fieldContainer > div {
    width: 100% !important;
    max-width: 100% !important;
  }
  
  .fieldContainer > div {
    width: 100% !important;
    max-width: 100% !important;
  }

  /* .formFieldDateSelector {
    width: 85% !important;
    max-width: 85% !important;
  } */
  .formFieldDateSelector {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width: 628px) {
  .fieldRow
  .halfWidth
  .halfWidth > div {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width: 420px) {
  .mobileTitleHeading {
    padding: 0px !important;
  }
  .formFieldDateSelector > div > span {
    padding-left: 15px;
  }
}
